import {
  ParsedParams,
  StrVal,
  parseEnum,
  NumCSV,
  Enum,
  NumVal,
  routesConfigHelper,
  BoolVal,
} from "@libs/router/url";
import { EmployeeStatus } from "api/employee/types";
import { DateRangeVal } from "utils/routing/dateRange";
import { timeFormat } from "utils/routing/timeFormat";

export const NO_STATUS_FILTER = "ALL";
export const DEFAULT_STATUS_FILTER = ["ACTIVE", "PENDING"];
export type EmployeeStatusQuery = EmployeeStatus | "ALL";

const EmployeeStatusMap: Record<EmployeeStatusQuery, null> = {
  ALL: null,
  ACTIVE: null,
  INACTIVE: null,
  ARCHIVED: null,
  PENDING: null,
  FURLOUGHED: null,
};

const EmployeeStatusSet = new Set(Object.keys(EmployeeStatusMap));
const EmployeesQueryParams = {
  search: StrVal,
  sortColumn: StrVal,
  orderBy: Enum<"ASCENDING" | "DESCENDING">().Val(["ASCENDING", "DESCENDING"]),
  status: {
    get: (val: string) => {
      const statuses = val.split(",");
      const parsed = statuses.map((s) => parseEnum(EmployeeStatusSet, s)) as EmployeeStatusQuery[];

      if (parsed.length === 1 && parsed[0] === NO_STATUS_FILTER) {
        return [] as EmployeeStatusQuery[];
      }

      return parsed;
    },
    set: NumCSV.set,
    defaultValue: DEFAULT_STATUS_FILTER,
  },
};

const FromQueryParam = {
  from: StrVal,
};

export const EmployeeParams = {
  id: NumVal,
};

export const EmployeeQuery = {
  ...FromQueryParam,
  edit: BoolVal,
};

const DocumentsQuery = {
  folderId: NumVal,
  docId: NumVal,
  ...EmployeeQuery,
};

const RoleIdPathParam = {
  roleId: NumVal,
};

const TimeSheetQuery = {
  ...EmployeeQuery,
  timeFormat,
  dateRange: DateRangeVal,
};

export const EmployeeRoutesConfig = routesConfigHelper({
  employee: {
    path: "/employees/profiles/:id",
    params: EmployeeParams,
    query: EmployeeQuery,
  },
  employeeTimesheet: {
    path: "/employees/profiles/:id/timesheet",
    params: EmployeeParams,
    query: TimeSheetQuery,
  },
  employeeDocuments: {
    path: "/employees/profiles/:id/documents",
    params: EmployeeParams,
    query: DocumentsQuery,
  },
  employeeRole: {
    path: "/employees/profiles/:id/role",
    params: EmployeeParams,
    query: EmployeeQuery,
  },
  employeeLicensing: {
    path: "/employees/profiles/:id/licensing",
    params: EmployeeParams,
    query: EmployeeQuery,
  },
  employeeScheduling: {
    path: "/employees/profiles/:id/scheduling",
    params: EmployeeParams,
    query: EmployeeQuery,
  },
  employeeReferral: {
    path: "/employees/profiles/:id/referral",
    params: EmployeeParams,
  },
  employees: {
    path: "/employees",
    query: EmployeesQueryParams,
  },
  roles: {
    path: "/employees/roles",
  },
  roleCreate: {
    path: "/employees/roles/create",
    query: FromQueryParam,
  },
  roleEdit: {
    path: "/employees/roles/:roleId/edit",
    params: RoleIdPathParam,
    query: FromQueryParam,
  },
  roleCopy: {
    path: "/employees/roles/:roleId/copy",
    params: RoleIdPathParam,
    query: FromQueryParam,
  },
  electronicPrescribing: {
    path: "/employees/electronic-prescribing",
  },
});

export type EmployeesQuery = ParsedParams<(typeof EmployeeRoutesConfig.employees)["query"]>;
export type EmployeesQueryUpdates = Partial<EmployeesQuery>;
