import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { PracticeInfoVO } from "@libs/api/generated-api";
import { isPracticeLive } from "@libs/utils/isPracticeLive";
import { CurrentUser } from "@libs/contexts/practice-portal/CurrentUserContext";

const Context = createContext({
  isPracticeLive: true,
});

Context.displayName = "PracticeConfigurationContext";
export const PracticeConfigurationContext = Context;

export const PracticeConfigurationProvider: React.FC<
  PropsWithChildren<{ practice: PracticeInfoVO; currentUser: CurrentUser }>
> = ({ practice, currentUser, children }) => {
  const value = useMemo(
    () => ({
      isPracticeLive: currentUser.type === "SUPPORT_USER" ? true : isPracticeLive(practice.onboardingStatus),
    }),
    [practice, currentUser.type]
  );

  return (
    <PracticeConfigurationContext.Provider value={value}>{children}</PracticeConfigurationContext.Provider>
  );
};

export const usePracticeConfiguration = () => useContext(PracticeConfigurationContext);
