import { add, addDays, fromUnixTime, isAfter } from "date-fns";
import { useMemo } from "react";
import {
  CustomAdjustmentTypeVO,
  FamilyMemberVO,
  LedgerV2EntryPaymentVO,
  PaymentSummaryVO,
  PaymentVO,
  PermissionConditionalContent,
  WalletVO,
} from "@libs/api/generated-api";
import { isOneOf } from "@libs/utils/isOneOf";
import { useCurrentUser } from "@libs/contexts/practice-portal/CurrentUserContext";
import {
  CollectionPaymentMethod,
  paymentMethodToLabel,
} from "components/PatientProfile/Billing/PaymentMethods/utils";
import { checkPermission } from "components/Roles/roleUtils";

export const TOOLTIP_ADJUSTMENT_CREDIT =
  "Adds a credit to the patient's account, lowering the balance they owe.";
export const TOOLTIP_ADJUSTMENT_DEBIT =
  "Adds an additional charge to the patient's account, increasing the balance they owe.";

export const PLACEHOLDER_WALLET_ID = "placeholder-wallet-id";

export const isPlaceholderWallet = (wallet: WalletVO) => {
  return wallet.uuid === PLACEHOLDER_WALLET_ID;
};

const findPatientWallet = (patientId: number, wallets?: WalletVO[]) =>
  wallets?.find((wallet) => wallet.patientId === patientId);

export const getFallbackPatientWallet = (
  patientId: number,
  practiceId: number,
  patientWallets?: WalletVO[]
) =>
  findPatientWallet(patientId, patientWallets) ?? {
    balance: 0,
    currency: "USD",
    name: "Patient Wallet",
    note: "",
    patientId,
    practiceId,
    practiceUuid: "fake-practice-uuid",
    type: "DEFAULT",
    uuid: PLACEHOLDER_WALLET_ID,
    isFamily: false,
  };

export const getPatientWallets = ({
  patientId,
  practiceId,
  patientWallets,
  includePlaceholderWallet,
}: {
  patientId: number;
  practiceId: number;
  patientWallets?: WalletVO[];
  includePlaceholderWallet: boolean;
}) => {
  const patientWallet = includePlaceholderWallet
    ? getFallbackPatientWallet(patientId, practiceId, patientWallets)
    : findPatientWallet(patientId, patientWallets);

  return [
    ...(patientWallet ? [patientWallet] : []),
    ...(patientWallets?.filter((wallet) => wallet.uuid !== patientWallet?.uuid) ?? []),
  ];
};

export type WalletWithFamilyMember = {
  wallet: WalletVO;
  familyMember?: FamilyMemberVO;
};

export const reconcileWalletsWithFamilyMembers: (args: {
  wallets: WalletVO[];
  familyMembers?: FamilyMemberVO[];
}) => WalletWithFamilyMember[] = ({ wallets, familyMembers }) =>
  wallets.map((wallet) => ({
    wallet,
    familyMember: familyMembers?.find((fm) => fm.memberPatientId === wallet.patientId),
  }));

export const getPaymentButtonLabel = ({
  isProcessing,
  paymentMethod,
  paymentType,
}: {
  isProcessing: boolean;
  paymentMethod: CollectionPaymentMethod;
  paymentType: PaymentVO["type"];
}) => {
  const noun = paymentType === "CHARGE" ? "Payment" : "Refund";

  if (isProcessing) {
    return `Processing ${noun}...`;
  }

  const verb = paymentType === "CHARGE" ? "Charge" : "Refund";
  const idleStatus = isPaymentMethodByCard(paymentMethod) ? verb : `Record ${noun}`;

  return idleStatus;
};

export const isPaymentStateFinal = (state: PaymentVO["state"]) =>
  !isOneOf(state, ["CREATED", "PENDING", "IN_PROGRESS", "AWAITING_RESULT"]);

export const isPaymentStateSuccessful = (state: PaymentVO["state"]) => isOneOf(state, ["SETTLED"]);

export const isPaymentStateFailed = (state: PaymentVO["state"]) =>
  isOneOf(state, ["VOID", "DENIED", "CHARGEBACK"]);

export const isPaymentMethodByCard = (method: CollectionPaymentMethod) =>
  isOneOf(method, ["STORED_POS", "STORED_PROFILE", "REFUNDABLE_CARD"]);

export const CC_REFUND_EXPIRATION_IN_DAYS = 365;

export const isRefundableByCard = (payment: PaymentVO) => {
  const paidByCard = isPaymentMethodByCard(payment.method);

  if (!paidByCard) {
    return false;
  }

  const paymentDate = fromUnixTime(payment.paymentCreatedAt);
  const refundExpirationDate = add(paymentDate, { days: CC_REFUND_EXPIRATION_IN_DAYS });
  const refundWindowExpired = isAfter(new Date(), refundExpirationDate);

  return !refundWindowExpired;
};

export const getMaxRefundableAmount = (payment?: PaymentVO, wallet?: WalletVO) => {
  const refundableAmountRemaining = payment?.refundableCurrencyAmount?.amount ?? 0;
  const walletBalance = wallet?.balance ?? 0;

  return {
    refundableAmountRemaining,
    maxRefundableAmount: Math.min(walletBalance, refundableAmountRemaining),
  };
};

export const isPaymentRefundableAmountGreaterThanWalletAmount = (payment?: PaymentVO, wallet?: WalletVO) => {
  const { refundableAmountRemaining, maxRefundableAmount } = getMaxRefundableAmount(payment, wallet);

  return refundableAmountRemaining > maxRefundableAmount;
};

export const getPreferredPaymentMethod = ({
  isRefund,
  hasPaymentProfiles,
  hasWalletBalance,
}: {
  isRefund: boolean;
  hasPaymentProfiles: boolean;
  hasWalletBalance: boolean;
}): CollectionPaymentMethod => {
  // For refunds, use eligible cards if any, otherwise use check
  if (isRefund) {
    return hasPaymentProfiles ? "REFUNDABLE_CARD" : "CHECK";
  }

  // If there is a balance on the wallet, always prefer using the wallet
  if (hasWalletBalance) {
    return "WALLET";
  }

  return "STORED_PROFILE";
};

/**
 * Returns the payment method associated with a payment object.
 *
 * @param payment - The payment object to extract the payment method from.
 * @returns The payment method associated with the payment object.
 */
const getPaymentMethod = (payment: PaymentVO | LedgerV2EntryPaymentVO | PaymentSummaryVO) =>
  "method" in payment ? payment.method : payment.paymentMethod;

/**
 * Returns the last four digits of the card associated with a payment object.
 *
 * @param payment - The payment object to extract the card last four from.
 * @returns The last four digits of the card associated with the payment object,
 * or undefined if no card is associated.
 */
const getCardLastFour = (payment: PaymentVO | LedgerV2EntryPaymentVO | PaymentSummaryVO) => {
  const method = getPaymentMethod(payment);

  if (isOneOf(method, ["STORED_POS", "STORED_PROFILE"]) && "paymentProfile" in payment) {
    return payment.paymentProfile?.card?.cardLastFour;
  }

  if (method === "EXTERNAL_POS" && "externalPosPayload" in payment) {
    return payment.externalPosPayload?.cardLastFour;
  }

  return undefined;
};

/**
 * Returns a label for the payment method associated with a payment object. If
 * the payment method is a stored card, the label includes the last four digits
 * of the card.
 *
 * @param payment - The payment object to extract the payment method and card
 * last four from.
 * @returns A label for the payment method associated with the payment object.
 */
export const getPaymentMethodToLabel = (payment: PaymentVO | LedgerV2EntryPaymentVO | PaymentSummaryVO) => {
  const method = getPaymentMethod(payment);

  const cardLastFour = getCardLastFour(payment);
  const creditAppliedFromInvoice =
    "balanceTransferFromInvoiceNumber" in payment ? payment.balanceTransferFromInvoiceNumber : undefined;

  const extra =
    cardLastFour ?? (creditAppliedFromInvoice ? `invoice #${creditAppliedFromInvoice}` : undefined);

  if (extra) {
    return `${paymentMethodToLabel[method]} - ${extra}`;
  }

  return paymentMethodToLabel[method];
};

/**
 * Checks if a payment is a family payment.
 *
 * @param payment - The payment object to check.
 * @returns True if the payment is a family payment, false otherwise.
 */
export const isFamilyPayment = (payment: PaymentVO | PaymentSummaryVO | LedgerV2EntryPaymentVO) =>
  ("familyPaymentAllocations" in payment && payment.familyPaymentAllocations.length > 0) ||
  ("familyPaymentCurrencyAmount" in payment && payment.familyPaymentCurrencyAmount != null) ||
  ("familyPaymentAmount" in payment && payment.familyPaymentAmount != null);

const getPaymentDate = (payment: PaymentVO | LedgerV2EntryPaymentVO | PaymentSummaryVO) => {
  return "createdAt" in payment ? fromUnixTime(payment.createdAt) : fromUnixTime(payment.paymentCreatedAt);
};

/**
 * Checks if a payment is editable based on the provided conditions.
 * @param payment - The payment object to check.
 * @param condition - The permission conditional content.
 * @returns A boolean indicating whether the payment is editable or not.
 */
export const isPaymentEditableChecker = (
  payment: PaymentVO | LedgerV2EntryPaymentVO | PaymentSummaryVO | undefined,
  condition: PermissionConditionalContent
) => {
  if (!payment || !condition.numberOfDaysLimit) {
    return true;
  }

  const paymentDate = getPaymentDate(payment);
  const limitDate = addDays(paymentDate, condition.numberOfDaysLimit);

  return !isAfter(new Date(), limitDate);
};

export const usePermittedAdjustments = (adjustments: CustomAdjustmentTypeVO[] | undefined) => {
  const currentUser = useCurrentUser();

  return useMemo(
    () =>
      adjustments?.filter((adjustment) => {
        return adjustment.entryType === "DEBIT"
          ? checkPermission(currentUser.roleV2, "BILLING", "ADD_ADJUSTMENT_CHARGE").isPermitted
          : checkPermission(currentUser.roleV2, "BILLING", "ADD_ADJUSTMENT_DISCOUNT").isPermitted;
      }),
    [adjustments, currentUser.roleV2]
  );
};

type FinixCardType = "DEBIT" | "CREDIT" | "HSA_FSA" | "RELOADABLE_PREPAID" | "NON_RELOADABLE_PREPAID";

export const getPaymentProfileTypeLabel = (cardType?: string) => {
  const finixCardType = cardType as FinixCardType | undefined;

  switch (finixCardType) {
    case "CREDIT": {
      return "Credit Card";
    }
    case "DEBIT": {
      return "Debit Card";
    }
    case "RELOADABLE_PREPAID":
    case "NON_RELOADABLE_PREPAID": {
      return "Prepaid Card";
    }
    case "HSA_FSA": {
      return "FSA/HSA Card";
    }
    default: {
      return "Card";
    }
  }
};
