import { FC, PropsWithChildren } from "react";

import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";

export const MessageField: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="flex flex-col flex-1 gap-y-2">
      <FormFieldLabel className="font-sansSemiBold text-xs" content="Message" />

      <div className="h-full bg-slate-50 rounded border border-slate-200 p-6">
        <div className="flex flex-col gap-y-3 h-full relative">{children}</div>
      </div>
    </div>
  );
};
