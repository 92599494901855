import { AppointmentPushEvent, AppointmentVO } from "@libs/api/generated-api";
import { isNullish } from "@libs/utils/types";
import { UpdateAppointmentRequest } from "api/scheduling/appointments/types";

export const getAppointmentRequest = (appointment: AppointmentVO) => {
  const args: UpdateAppointmentRequest = {
    newProcedures: [],
    ignoreFeeCalcError: true,
    editedProcedureIds: appointment.patientProcedures.map((pp) => pp.id),
    state: appointment.state,
    patientId: appointment.patient.id || 0,
    duration: appointment.duration,
    startTime: appointment.startTime,
    providerId: appointment.provider.id,
    dentistId: appointment.dentist.id,
    roomId: appointment.room.id,
    date: appointment.date,
    sendConfirmationAndReminders: appointment.sendConfirmationAndReminders,
    appointmentCategoryId: appointment.categoryName?.id,
    comments: appointment.comments,
    asap: appointment.asap,
    color: appointment.color,
    tags: {
      customIds: appointment.tags?.customAppointment.map((tag) => tag.id) ?? [],
    },
  };

  return args;
};

export const UNSCHEDULED_APPT_DATE = "2099-01-01";
export const isUnscheduledAsapAppt = (date?: string) => date === "2099-01-01";

export const isUnscheduledUpdate = ({
  state,
  origState,
  asap,
  origAsap,
  date,
  origDate,
}: AppointmentPushEvent) => {
  const isOriginallyUnscheduledAsap = origAsap && isUnscheduledAsapAppt(origDate);
  const isUnscheduledAsap = asap && isUnscheduledAsapAppt(date);
  const isOriginallyUnscheduled = origState === "UNSCHEDULED";
  const isUnscheduled = state === "UNSCHEDULED";

  if (isNullish(origState) && (isUnscheduled || isUnscheduledAsap)) {
    return true;
  }

  if (state === "_DELETED" && (isOriginallyUnscheduled || isOriginallyUnscheduledAsap)) {
    return true;
  }

  if (isUnscheduled && isOriginallyUnscheduled) {
    return true;
  }

  if (isUnscheduledAsap && isOriginallyUnscheduledAsap) {
    return true;
  }

  return false;
};

export const isUnscheduledAsapUpdate = ({ state, asap, origAsap, date, origDate }: AppointmentPushEvent) => {
  const isOriginallyUnscheduledAsap = origAsap && isUnscheduledAsapAppt(origDate);
  const isUnscheduledAsap = asap && isUnscheduledAsapAppt(date);

  if (isNullish(origAsap) && isUnscheduledAsap) {
    return true;
  }

  if (state === "_DELETED" && isOriginallyUnscheduledAsap) {
    return true;
  }

  if (isUnscheduledAsap && isOriginallyUnscheduledAsap) {
    return true;
  }

  return false;
};

export const isUnscheduledState = ({
  state,
  date,
  asap,
}: Partial<Pick<AppointmentVO, "state" | "date" | "asap">>) =>
  state === "UNSCHEDULED" || (asap && isUnscheduledAsapAppt(date));
