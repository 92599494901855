import { FC } from "react";
import { Navigate } from "react-router-dom";
import { useCurrentUser } from "@libs/contexts/practice-portal/CurrentUserContext";
import { checkPermission } from "components/Roles/roleUtils";
import { paths } from "utils/routing/paths";

export const HomeRoute: FC = () => {
  const currentUser = useCurrentUser();

  return checkPermission(currentUser.roleV2, "APPOINTMENT_MANAGEMENT", "ACCESS_ALL").isPermitted ? (
    <Navigate replace to={paths.schedule()} />
  ) : checkPermission(currentUser.roleV2, "PRACTICE_SETTINGS", "ACCESS_ALL").isPermitted ? (
    <Navigate replace to={paths.settingsSection({ section: "business-information" })} />
  ) : (
    <Navigate replace to={paths.employee({ id: currentUser.id })} />
  );
};
