import { PropsWithChildren } from "react";
import { usePracticeConfiguration } from "@libs/contexts/practice-portal/PracticeConfigurationContext";
import OnboardingImage from "assets/images/onboarding-personal-info.svg";

export const LivePracticeGuard: React.FC<PropsWithChildren> = ({ children }) => {
  const { isPracticeLive } = usePracticeConfiguration();

  return isPracticeLive ? (
    children
  ) : (
    <div
      className={`
        flex
        flex-col
        gap-8
        items-center
        justify-center
        h-[90vh]
        w-full
      `}
    >
      <img alt="Pen, Notebook, Mug, and badge" src={OnboardingImage} />
      <div className="flex flex-col items-center justify-center text-center">
        <div className="font-sansSemiBold text-xl">Data Coming Soon</div>
        <div className="font-sans text-base mt-4 mb-8 max-w-3xl">
          Your practice information will appear here once the data from your current practice management
          system has been loaded into Archy. Questions? Contact us at onboarding@archy.com.
        </div>
      </div>
    </div>
  );
};
