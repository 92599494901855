import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { HubCallback, Hub } from "@aws-amplify/core";
import { LoadingOverlaySpinner } from "@libs/components/UI/LoadingOverlaySpinner";
import { useStorageContext } from "@libs/contexts/StorageContext";

import { usePracticeActivityStorage } from "storage/activity";
import { postAuthChannelMessage } from "hooks/useAuthChannelListeners";
import { paths } from "utils/routing/paths";

export const OAuthSignIn: FC = () => {
  const navigate = useNavigate();
  const storage = useStorageContext();
  const activityStorage = usePracticeActivityStorage();

  useEffect(() => {
    const onMessage: HubCallback = (listener) => {
      if (listener.payload.event !== "customOAuthState") {
        return;
      }

      let returnUrl: string | undefined;
      let lastUserId: number | undefined;

      try {
        const params = JSON.parse(listener.payload.data as string) as {
          returnUrl?: string;
          lastUserId?: number;
        };

        if (params.returnUrl) {
          returnUrl = params.returnUrl;
        }

        if (params.lastUserId) {
          lastUserId = params.lastUserId;
        }
      } catch {
        // if there's an error a user will still get redirected
      }

      activityStorage.setRecentlyActive();
      postAuthChannelMessage({ type: "signIn" });
      navigate(paths.selectAccount({ returnUrl, lastUserId }));
    };

    return Hub.listen("auth", onMessage);
  }, [storage.localStorage, activityStorage, navigate]);

  return <LoadingOverlaySpinner loadingText="Signing In" centerVertically />;
};
